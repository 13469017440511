import React, { createContext } from 'react';

const initialState = {
  processing: false,
  progress: 0,
  sent: false,
  loading: false,
  values: {},
  error: false,
  failed: false,
};

export const FormContext = createContext(initialState);

export default class FormProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
    this.handleChange = this.handleChange.bind(this);
    this.getFieldId = this.getFieldId.bind(this);
    this.getValue = this.getValue.bind(this);
    this.bulkChange = this.bulkChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkCondition = this.checkCondition.bind(this);
    this.toggleProcessing = this.toggleProcessing.bind(this);
    this.toggleSuccess = this.toggleSuccess.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.formProgress = this.formProgress.bind(this);
    this.formError = this.formError.bind(this);
    this.formSuccess = this.formSuccess.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.setFailed = this.setFailed.bind(this);
  }

  handleChange = e => {
    const { values } = this.state;
    this.setState({
      values: {
        ...values,
        [e.target.name]: e.target.value,
      },
    });
  };

  // eslint-disable-next-line class-methods-use-this
  getFieldId(label) {
    return label
      ?.replace(/[^A-Z0-9-]/gi, ' ')
      .replace(/\s+/g, '_')
      .toLowerCase();
  }

  getValue(id) {
    const { values } = this.state;
    return values[id];
  }

  bulkChange = data => {
    const { values } = this.state;
    this.setState({
      values: {
        ...values,
        ...data,
      },
    });
  };

  handleCheck = e => {
    const { values } = this.state;
    this.setState({
      values: {
        ...values,
        [e.target.name]: e.target.checked,
      },
    });
  };

  handleSubmit = async e => {
    const form = e.target;

    e.preventDefault();

    const { values } = this.state;
    const { action, debug } = this.props;

    this.toggleProcessing();
    if (action) {
      // Custom form processing
      action({
        data: values,
        success: this.formSuccess,
        error: this.formError,
        progress: this.formProgress,
        reset: this.resetForm,
      });
    } else if (debug) {
      // Form debugging
      setTimeout(() => {
        alert(`Form Submitted\n\nData: ${JSON.stringify(values, null, '\t')}`);
        this.formSuccess();
      }, 1000);
    } else {
      /* Grab user IP address */
      const user = await fetch('https://ipapi.co/json/')
        .then(response => response.json())
        .catch(() =>
          this.formError(
            `Unable to process request. Please disable any adblockers or VPN's to proceed.`,
          ),
        );

      const data = { ...values, user };

      delete data.processing;
      delete data.sent;
      delete data.gdpr_consent;

      // Default contact form
      const submissionUrl = `${process.env.GATSBY_FUNCTIONS_URL}contact`;
      const body = JSON.stringify({
        form: form.getAttribute('name'),
        ...data,
      });

      fetch(submissionUrl, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body,
      })
        .then(response => response.json())
        .then(response => {
          this.toggleProcessing();
          if (response.contact) {
            this.toggleSuccess(
              `Thanks for getting in touch. One of our team will get back to you within 24 hours.`,
            );
          } else {
            this.formError({
              message:
                'An unexpected error has occurred. Please try again shortly.',
            });
          }
        })
        .catch(error => this.formError(error));
    }
  };

  setFailed(failed) {
    this.setState({ failed });
  }

  checkCondition = condition => {
    const { values } = this.state;

    const operators = {
      eq: (a, b) => a === b,
      ne: (a, b) => a !== b,
    };

    const show = condition.every(x => {
      const field = values[x.field];
      const check = operators[x.operator];

      if (field) {
        return check(field, x.value);
      }
      return false;
    });

    return show;
  };

  toggleProcessing() {
    const { processing } = this.state;
    this.setState({ processing: !processing, error: false, sent: false });
  }

  toggleSuccess(message) {
    this.setState({ sent: message, progress: 0 });
  }

  toggleLoading() {
    const { loading } = this.state;
    this.setState({ loading: !loading });
  }

  formProgress(val) {
    const progress = (val.loaded / val.total) * 100;
    this.setState({ progress });
  }

  formError(error) {
    this.setState({ error: error.message, processing: false });
  }

  formSuccess(message = `Form submitted successfully`) {
    this.toggleProcessing();
    this.toggleSuccess(message);
  }

  resetForm() {
    this.setState(initialState);
  }

  render() {
    const { children } = this.props;
    const { values, processing, progress, sent, loading, error, failed } =
      this.state;

    // console.log(`form state`, this.state);

    const formData = {
      values,
      processing,
      progress,
      sent,
      loading,
      error,
      failed,
      handleChange: this.handleChange,
      bulkChange: this.bulkChange,
      handleCheck: this.handleCheck,
      handleSubmit: this.handleSubmit,
      checkCondition: this.checkCondition,
      getFieldId: this.getFieldId,
      getValue: this.getValue,
      toggleProcessing: this.toggleProcessing,
      toggleSuccess: this.toggleSuccess,
      toggleLoading: this.toggleLoading,
      formProgress: this.formProgress,
      formError: this.formError,
      setFailed: this.setFailed,
    };

    return (
      <FormContext.Provider value={formData}>{children}</FormContext.Provider>
    );
  }
}
