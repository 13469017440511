import React from 'react';
import { Container as UIContainer } from '@web-passion/uikit';

export default function Container(props) {
  const { children, className, ...other } = props;
  return (
    <UIContainer className={className} {...other}>
      {children}
    </UIContainer>
  );
}
